import React from "react"

import LogoAndImages from "./LogoAndImages"

export interface AboutContainerProps {
  pageContext: { [key: string]: any }
}

const LogoAndImagesContainer: React.FC<AboutContainerProps> = ({
  pageContext,
}) => {
  return <LogoAndImages {...pageContext} />
}

export default LogoAndImagesContainer
