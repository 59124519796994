import React from "react"

import SEO from "src/components/SEO"
import LogoAndImagesContentNyc from "./LogoAndImagesContentNyc"
import LogoAndImagesContentLa from "./LogoAndImagesContentLa"

export default function AboutContent() {
  const city = process.env.GATSBY_CITY
  const Content =
    { nyc: LogoAndImagesContentNyc, la: LogoAndImagesContentLa }[city] ??
    (() => null)

  return (
    <>
      <SEO title="Logo and Images" />
      <Content />
    </>
  )
}
