import React from "react"

import ContentContainer from "../../containers/ContentContainer"
import BackLink from "src/components/BackLink"
import LogoAndImagesContent from "./LogoAndImagesContent"
import Footer from "src/components/Footer"

export default function About() {
  return (
    <>
      <ContentContainer>
        <BackLink link="/about" text="Back" />
        <LogoAndImagesContent />
      </ContentContainer>

      <Footer />
    </>
  )
}
