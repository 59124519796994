import React from "react"
import styled from "styled-components"

import StandardContent from "src/components/StandardContent"
import PageTitleWithImage from "src/components/PageTitleWithImage"
import Markdown from "src/components/Markdown"
import ContentPageStyles from "src/styles/ContentPageStyles"
import logo from "src/images/logo-and-images/NYC Vintage Map logo.png"
import screenshot1 from "src/images/logo-and-images/NYC Vintage Map Screenshot 1.png"
import screenshot2 from "src/images/logo-and-images/NYC Vintage Map Screenshot 2.png"
import screenshot3 from "src/images/logo-and-images/NYC Vintage Map Screenshot 3.png"
import screenshot4 from "src/images/logo-and-images/NYC Vintage Map Screenshot 4.png"
import screenshot5 from "src/images/logo-and-images/NYC Vintage Map Screenshot 5.png"
import illustrationPortrait from "src/images/logo-and-images/NYC Vintage Map Illustration Portrait.png"
import illustrationSquare from "src/images/logo-and-images/NYC Vintage Map Illustration Square.png"
import illustrationLandscape from "src/images/logo-and-images/NYC Vintage Map Illustration Landscape.png"
import Space from "src/components/Space"

export default function LogoAndImagesContentNyc() {
  return (
    <ContentPageDiv>
      <StandardContent>
        <PageTitleWithImage
          preTitle="NYC Vintage Map"
          title="Logo and Images"
        />

        <ContentPageStyles>
          <Markdown>
            You can use images on this page in connection with posts and
            articles about NYC Vintage Map. When using online, please link to
            NYC Vintage Map website. On Instagram and TikTok, tag
            [@nycvintagemap](https://instagram.com/nycvintagemap).
          </Markdown>
          <Space v={20} />

          <Markdown>Right-click / long-press on an image to save.</Markdown>

          <H2>Logo</H2>
          <ImageDiv>
            <LogoImg src={logo} alt="NYC Vintage Map logo" />
          </ImageDiv>

          <H2>Screenshots</H2>
          <ScreenshotsDiv>
            <WideImage src={screenshot1} alt="NYC Vintage Map screenshot 1" />
            <WideImage src={screenshot2} alt="NYC Vintage Map screenshot 2" />
            <WideImage src={screenshot3} alt="NYC Vintage Map screenshot 3" />
            <WideImage src={screenshot4} alt="NYC Vintage Map screenshot 4" />
            <WideImage src={screenshot5} alt="NYC Vintage Map screenshot 5" />
          </ScreenshotsDiv>

          <H2>Illustration Portrait</H2>
          <ImageDiv>
            <WideImage
              src={illustrationPortrait}
              alt="NYC Vintage Map illustration portrait"
            />
          </ImageDiv>

          <H2>Illustration Square</H2>
          <ImageDiv>
            <WideImage
              src={illustrationSquare}
              alt="NYC Vintage Map illustration square"
            />
          </ImageDiv>

          <H2>Illustration Landscape</H2>
          <ImageDiv>
            <WideImage
              src={illustrationLandscape}
              alt="NYC Vintage Map illustration landscape"
            />
          </ImageDiv>

          <Space v={30} />

          <Markdown>Right-click / long-press on an image to save.</Markdown>
        </ContentPageStyles>
      </StandardContent>
    </ContentPageDiv>
  )
}

const ContentPageDiv = styled.div`
  padding: 20px 0 0;
`

const H2 = styled.h2`
  font-size: 20px;
  font-weight: bold;
`

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
`

const LogoImg = styled.img`
  width: 160px;
  height: 160px;
`

const ScreenshotsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
const WideImage = styled.img`
  width: 100%;
`
