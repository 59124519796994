import React from "react"
import styled from "styled-components"

export interface Props {
  preTitle?: string
  title: string
  imageUrl?: string
  innerShadow?: boolean
}

export default function PageTitleWithImage({
  preTitle,
  title,
  imageUrl,
  innerShadow = true,
}: Props) {
  return (
    <GroupDiv>
      {imageUrl && (
        <ProfileDiv>
          <ProfileImg src={imageUrl} alt={title} />
          {innerShadow && <InnerShadowDiv />}
        </ProfileDiv>
      )}

      <H1>
        {preTitle && <PreTitleSpan>{preTitle}</PreTitleSpan>}
        {title}
      </H1>
    </GroupDiv>
  )
}

const GroupDiv = styled.div`
  display: flex;
  align-items: center;
`

const ProfileDiv = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 80px;
  height: 80px;
  background: #eeeeee;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 14px;
`

const ProfileImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const InnerShadowDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 2px 12px rgba(0, 0, 0, 0.1);
`

const H1 = styled.h1`
  font-size: 27px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  color: black;
  letter-spacing: 0.01em;
`

const PreTitleSpan = styled.span`
  display: block;
  font-size: 13px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
`
