import styled from "styled-components"
import { primaryColor } from "./styleConstants"

const ContentPageStyles = styled.div`
  padding: 20px 0 40px;

  & h1 {
    font-size: 28px;
  }

  /* Ensure that first element (like h1) doesn't add too much spacing */
  & *:first-child {
    margin-top: 5px;
  }

  & h2 {
    font-size: 19px;
    line-height: 24px;
    margin: 32px 0 8px;
  }

  & p,
  li {
    font-size: 15px;
    line-height: 20px;
  }

  & a {
    color: ${primaryColor};
  }

  & ul {
    padding: 0 0 0 26px;
  }

  & li {
    margin: 0 0 15px;
  }
`

export default ContentPageStyles
